import "../Style/style.css";
import backgroundImage from "../Assets/event.jpg";
import Webcam from "react-webcam";
import axios from "axios";
import { Cloudinary } from "@cloudinary/url-gen";
import { Effect } from "@cloudinary/url-gen/actions/effect";
import { useEffect, useRef, useState } from "react";
import logo from "./logs.png";
import { v4 as uuidv4 } from "uuid";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";
import DotLoader from "react-spinners/DotLoader";
import Loaders from "./Loaders.jsx";

let cloudName = "deanyxmez";
const cld = new Cloudinary({
  cloud: {
    cloudName,
  },
});

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

function applyFilter(filter, image) {
  switch (filter) {
    case "artistic":
      return image.effect(Effect.artisticFilter("fes"));
    case "sepia":
      return image.effect(Effect.sepia());
    case "cartoonify":
      return image.effect(Effect.cartoonify());
    case "vignette":
      return image.effect(Effect.vignette());
    case "oilpaint":
      return image.effect(Effect.oilPaint());
    case "grayscale":
      return image.effect(Effect.grayscale());
    case "vectorize":
      return image.effect(Effect.vectorize());
    case "pixelate":
      return image.effect(Effect.pixelate());
    default:
      return image;
  }
}

const filters = [
  "none",
  "artistic",
  "sepia",
  "cartoonify",
  "vignette",
  "oilpaint",
  "grayscale",
  "vectorize",
  "pixelate",
];

function ImagePreviewer({ url, deleteImage, hideCamera }) {
  return url ? (
    <div className="img_box">
      <img src={url} alt="my_image" />
      <div className="button_container">
        <button className="action_btn delete_btn" onClick={deleteImage}>
          Delete
        </button>
        <button className="action_btn continue_btn" onClick={hideCamera}>
          Save
        </button>
      </div>
    </div>
  ) : null;
}

function FilterBox({ imgId, setPrevURL, filterName }) {
  let image = cld.image(imgId);
  image = applyFilter(filterName, image);
  const imgURL = image.toURL();
  return (
    <div className="filter_item" onClick={() => setPrevURL(imgURL)}>
      <img src={imgURL} alt={filterName} />
      <span className="filter_des">{filterName}</span>
    </div>
  );
}

const App = () => {
  const backgroundColor = "#ff4d00";
  const backLinearColor = "#d24102";
  let [color, setColor] = useState("#ffffff");
  let [launch, setLaunch] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [flag, setFlag] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [comments, setComments] = useState("");
  const [photo, setPhoto] = useState(false);
  const [save, setSave] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [continu, setContinue] = useState(true);

  const savingIMG = () => {
    setSave(true);
  };

  const noSave = () => {
    setSave(false);
  };

  const getFormattedDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const constraints = {
    width: 700,
    height: 550,
    facingMode: "user",
    aspectRatio: 9 / 16,
  };
  const camRef = useRef();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [prevURL, setPrevURL] = useState("");

  const capture = async () => {
    const data = camRef.current.getScreenshot();
    try {
      setLoading(true);
      const imageData = new FormData();
      imageData.append("file", data);
      imageData.append("upload_preset", "ackesselfie");
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        imageData
      );
      const imageDetails = res.data;
      setId(imageDetails.public_id);
      setPrevURL(imageDetails.url);
      setImageUrl(res.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModel = () => {
    setFlag(false);
  };

  const submit = (e) => {
    setLaunch(true);
    setContinue(false);
    //   window.location.reload();
  };

  const hideCamera = () => {
    setLaunch(false);
    setSave(true);
    setCaptured(false);
    setContinue(true);

    setLoadings(true);

    if (name && phone && title && imageUrl) {
      update(ref(db, `fareed/${generateUUID()}`), {
        name: name,
        title: title,
        phone: phone,
        comments: comments,
        imageUrl: imageUrl,
        datetime: getFormattedDate(),
      }).then(() => {
        setStatus(" Event entry Successful");
        setFlag(true);
        setLoadings(false);
        setPhoto(false);
        setName("");
        setPhone("");
        setTitle("");
        setComments("");
        setImageUrl("");
        setLaunch(false);
      });
    } else {
      setPhoto(true);
      setStatus("Fill All Details");
      setFlag(true);

      setLoadings(false);
    }
  };

  const deleteImage = () => {
    setImageUrl("");
    setId("");
    setStatus("Image Deleted, Capture another Image!");
    window.location.reload();
  };

  function generateUUID() {
    return uuidv4();
  }

  useEffect(() => {
    return () => {};
  }, []);

  const reloadWindow = () => {
    window.location.reload();
  };

  return (
    <>
      {launch && (
        <section className="main">
          <article className="media_box">
            <div className="camera">
              <Webcam
                ref={camRef}
                className="webcam"
                videoConstraints={constraints}
                screenshotFormat="image/jpeg"
              />
              {!prevURL && (
                <div
                  disabled={loading}
                  onClick={capture}
                  className="capture_btn"
                >
                  {/* 📸 */}
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    src={logo}
                  />
                </div>
              )}
            </div>

            <ImagePreviewer
              url={prevURL}
              deleteImage={deleteImage}
              hideCamera={hideCamera}
            />
          </article>

          {!prevURL && (
            <article className="filter_container">
              <div className="filter_scroll">
                {id &&
                  filters.map((filter, index) => (
                    <FilterBox
                      imgId={id}
                      filterName={filter}
                      setPrevURL={setPrevURL}
                      key={index}
                    />
                  ))}
              </div>
            </article>
          )}
        </section>
      )}

      {continu && (
        <div className="container">
          <div className="mainbody">
            <div className="profileImage">
              <img src={logo} />
            </div>

            <div className="note">
              <p>EVENT ENTRY</p>
            </div>

            {flag && (
              <div className="staaa">
                <p>{status}</p>{" "}
                {photo ? (
                  <div className="confi">
                    <div
                      className="star"
                      style={{
                        backgroundColor: "red",
                      }}
                      onClick={reloadWindow}
                    >
                      No
                    </div>

                    <div className="star" onClick={closeModel}>
                      Yes
                    </div>
                  </div>
                ) : (
                  <div className="star" onClick={closeModel}>
                    Okay
                  </div>
                )}
              </div>
            )}

            <div className="inputsect">
              <div className="inputpoints">
                <label>Full Name</label>

                <input
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Full Name "
                  className="mt-1 block w-full"
                  autoComplete="name"
                  isFocused={true}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="inputpoints">
                <label>Your Title</label>

                <input
                  type="text"
                  name="title"
                  value={title}
                  placeholder="Your Title"
                  className="mt-1 block w-full"
                  autoComplete="email"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>

              <div className="inputpoints">
                <label>Phone</label>

                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  className="mt-1 block w-full"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              {/* <div className="inputpoints">
              <label>Email</label>

              <input
                type="text"
                name="email"
                value={email}
                placeholder="Email address"
                className="mt-1 block w-full"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div> */}

              <div className="inputpoints">
                <label>Event expectations </label>

                <textarea
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    padding: "10px",
                    outline: "none",
                    fontSize: "0.8em",
                    borderRadius: "5px",
                    border: "1px solid white", // Optional: Add a border if needed
                  }}
                  name="comments"
                  id="email"
                  type="email"
                  value={comments}
                  placeholder="Enter your expectations "
                  className="mt-1 block w-full"
                  autoComplete="comments"
                  onChange={(e) => setComments(e.target.value)}
                  required
                />
              </div>

              {loadings ? (
                <div className="spin">
                  {" "}
                  <DotLoader
                    color={color}
                    loading={loadings}
                    // cssOverride={override}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="logbtn" onClick={submit}>
                Capture Photo
              </div>

              <div className="lastquote">
                <p>@evententry 2024</p>
          
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
